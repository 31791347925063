import {useEffect, useState} from "react";
import moment from "moment-timezone";
import {toLocalTimezone} from "../../../factories/time";
import {isUndefined} from "is-type-util";

export interface ITimeAgoLogic {
  timeAgo?: string;
}

const defaultUpdateMs = 5000;
export const useTimeAgoLogic = (timestamp?: string): ITimeAgoLogic => {
  const [timeState, setTimeState] = useState<moment.Moment | undefined>();
  const [timeAgo, setTimeAgo] = useState<string | undefined>();

  useEffect(() => {
    if (isUndefined(timestamp)) {
      setTimeState(undefined);
    } else {
      setTimeState(toLocalTimezone(timestamp));
    }
  }, [timestamp]);

  useEffect(() => {
    if (isUndefined(timeState)) {
      setTimeAgo(undefined);
      // return to exit
      return;
    }
    // update time ago
    setTimeAgo(timeState?.fromNow())
    const updateTimeInterval = setInterval(
      () => setTimeAgo(timeState?.fromNow(true))
      , defaultUpdateMs
    );

    return () => clearInterval(updateTimeInterval);
  }, [timeState]);
  return {
    timeAgo,
  };
}
