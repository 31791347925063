
export const toStringArray = (input?: string | string[]): string[] => {
  if (typeof input === "string"){
    return [input];
  } else if (Array.isArray(input)){
    // already array
    return input;
  }
  // must be undefined, return empty array
  return [];
}

export const flattenArray = <InputTypes = any>(...inputs: MultiLevelNestedArray<InputTypes>): InputTypes[] =>
  inputs.flat(Infinity) as InputTypes[];

export const uniqueArray = <InputType = any>(input: InputType[]): InputType[] => {
  const uniqueSetFromInput = new Set<InputType>([...input]);
  return [
    ...Array.from(uniqueSetFromInput.values()),
  ];
}

export const cleanFalsyFromArray = <InputType = any>(input: (undefined | InputType)[]): InputType[] => input.filter((value) => !!value) as InputType[];

export const arrayToSpacedString = (input: (string|number)[] | string | number): string => {
  switch (typeof input){
    case "string":
      // already a string, return as is
      return input;
    case "number":
      // return number to string
      return input.toString();
    default:
      // must be an array
      return input.join(` `);
  }
}


