import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {isDefined, isUndefined} from "is-type-util";
import {IScreenData, ScreenUuidType} from "../../services/firebase/database-types";


export interface IScreenDataClear extends Partial<Omit<IScreenData, "uuid">> {
  uuid?: ScreenUuidType;
}
export interface IScreenDataConnected extends Partial<Omit<IScreenData, "uuid">> {
  uuid: ScreenUuidType;
}

export type ScreenStoreType = IScreenDataClear | IScreenDataConnected | IScreenData;
const initialState: ScreenStoreType = {

};


export const screenSlice = createSlice({
  name: `screen`,
  initialState,
  reducers: {
    setUuid: (state, action: PayloadAction<ScreenUuidType>) => {
      const uuid = action.payload;
      if (state.uuid === uuid) {
        // nothing to do
        return state;
      }
      // clear state and set uuid
      return {
        ...initialState,
        uuid,
      };
    },
    setData: (state, action: PayloadAction<IScreenDataClear|IScreenData|undefined>) => {
      const data = action.payload;
      if (isUndefined(data) || data == null) {
        // nothing to do, reset to initial state
        const {uuid} = state;
        return {
          ...initialState,
          uuid,
        };
      }
      let {uuid, teamMembers, serviceBoards, ...otherScreenData} = data;
      if (isDefined(teamMembers)) {
        teamMembers = Object.values(teamMembers);
      }
      if (isDefined(serviceBoards)) {
        serviceBoards = Object.values(serviceBoards);
      }
      if (state.uuid === uuid){
        // update existing uuid data
        return {
          ...state,
          ...otherScreenData,
          teamMembers,
          serviceBoards,
        };
      }

      return {
        ...initialState,
        ...otherScreenData,
        teamMembers,
        serviceBoards,
        uuid,
      };
    },
    clear: (state) => {
      return {
        ...initialState,
      };
    },

  }
});

export const {setUuid: setScreenUuid, clear: clearScreen, setData: setScreenData} = screenSlice.actions;
export const screenReducer = screenSlice.reducer;


