import {arrayToSpacedString, cleanFalsyFromArray, flattenArray, toStringArray, uniqueArray} from "./array-factory";

export type ClassNameType = string | string[];

export interface IConditionalClassConfig {
  condition?: boolean;
  classNames: ClassNameType;
}

export type classNamePropTypes = undefined | ClassNameType | IConditionalClassConfig;

export const isClassNameType = (input: classNamePropTypes | object): input is ClassNameType => {
  return (
    // not undefined
    (input !== undefined)
    && (
      // is string
      (typeof input === "string")
      // or array
      || (Array.isArray(input))
    )
  );
}

export const isConditionalClassConfig = (input: classNamePropTypes | object): input is IConditionalClassConfig => {
  // check for easy to exclude options first
  if (
    // undefined
    input === undefined
    // is ClassNameType
    || isClassNameType(input)
  ) {
    return false;
  }

  if (!(`classNames` in input)){
    // can't be without input.classNames
    return false;
  }

  let expectedTotalKeys = 1;
  if (`condition` in input){
    expectedTotalKeys++;
  }

  // should have only have keys for expectedTotalKeys
  return (Object.keys(input).length === expectedTotalKeys);
}

export const processConditionalClass = (input: IConditionalClassConfig): undefined | ClassNameType =>
  input.condition ? input.classNames : undefined;

export const withClassNames = (...inputClassNames: (undefined | IConditionalClassConfig | string | string[])[]): string => {
  const conditionalClasses: ClassNameType[] = cleanFalsyFromArray(
    inputClassNames.filter(isConditionalClassConfig)
      .map(processConditionalClass)
      .map(toStringArray)
  );
  const inputClasses: ClassNameType[] = cleanFalsyFromArray(
    inputClassNames.filter<ClassNameType>(isClassNameType)
      .map<string[]>(toStringArray)
  );
  return arrayToSpacedString(
    toStringArray(
      uniqueArray<string>(
        flattenArray<string>([conditionalClasses, inputClasses])
      )
    )
  );

}
