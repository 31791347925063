import React, {useEffect} from "react";
import type {FunctionComponent} from "react";
import s from "./NeedSetupPage.module.scss";
import {useAppSelector} from "../../redux/hooks";
import {getScreenUuid} from "../../redux/screen/screen.selectors";
import {displayScreenNeedsSetupEvent} from "../../services/log/events";

export interface IUnassignedPageProps {
}

export const NeedSetupPage: FunctionComponent<IUnassignedPageProps> = ({}) => {
  const screenUuid = useAppSelector(getScreenUuid);
  useEffect(() => {
    displayScreenNeedsSetupEvent();
  }, []);
  return (
    <div className={s.container}>
      <div className={s.title}>Not Configured</div>
      <div className={s.uuid}>UUID: {screenUuid}</div>
    </div>
  );
}

