import React, {useEffect} from "react";
import type {FunctionComponent} from "react";
import s from "./WallboardPage.module.scss";
import {TeamMemberName} from "../../modules/team-member/modules/name/TeamMemberName";
import {TeamMembers} from "../../modules/team-members/TeamMembers";
import {TeamMemberAssignedTotal} from "../../modules/team-member/modules/assigned-total/TeamMemberAssignedTotal";
import {WallboardTop} from "./modules/top/WallboardTop";
import {useWallboardPageLogic} from "./WallboardPage.logic";
import {withClassNames} from "../../factories/with-class-names";
import {displayScreenThemeEvent} from "../../services/log/events";

const TempTeamMembersContainer = () => {
    return (

      <div className={`team-members-container`}>
          {/** team member repeat start */}
          <div className={`team-member`}>
              <div className={`team-member-top`}>
                  <TeamMemberName teamMemberId={148} />
                  <TeamMemberAssignedTotal teamMemberId={148} />
              </div>
              <div className={`team-member-activity`} >{/** themed */}
                  <div className={`team-member-activity-details`}>
                      <div className={`team-member-activity-title`}>timeTitle</div>
                      <div className={`team-member-activity-type`}>timeType</div>
                  </div>
                  <div className={`team-member-activity-time`}>HH:MM</div>
              </div>

          </div>
          {/** team member repeat stop */}

      </div>
    );
}
export interface IWallboardPageProps {

}

const WallboardPage: FunctionComponent<IWallboardPageProps> = ({}) => {
    const {theme} = useWallboardPageLogic();
  useEffect(() => {
    displayScreenThemeEvent(theme);
  }, [theme]);
    return (
        <div className={withClassNames(s.container, `theme_${theme}`)}>
            <WallboardTop />
            <TeamMembers />
        </div>
    );
};

export default WallboardPage;
