import React from "react";
import type {FunctionComponent} from "react";
import s from "./TeamMemberAssignedTotal.module.scss";
import {useTeamMemberAssignedTotalLogic} from "./TeamMemberAssignedTotal.logic";

export interface ITeamMemberAssignedTotalProps {
  teamMemberId: number;
}

export const TeamMemberAssignedTotal: FunctionComponent<ITeamMemberAssignedTotalProps> = ({teamMemberId}) => {
  const totalTicketsAssigned = useTeamMemberAssignedTotalLogic(teamMemberId);
  return <div className={s.ticketCount}> {totalTicketsAssigned} </div>;
}

