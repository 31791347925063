import React from "react";
import type {VoidFunctionComponent} from "react";
import s from "./ServiceBoardAvailableTickets.module.scss";
import {useServiceBoardAvailableTicketsLogic} from "./ServiceBoardAvailableTickets.logic";

export interface IServiceBoardAvailableTicketsProps {
  serviceBoardId: number;
}

export const ServiceBoardAvailableTickets: VoidFunctionComponent<IServiceBoardAvailableTicketsProps> = ({serviceBoardId}) => {
  const {low, medium, high, critical} = useServiceBoardAvailableTicketsLogic(serviceBoardId);
  return (
    <div className={s.container}>
      {!!critical && <div className={`service-board-stats-critical`}>c:{critical}</div>}
      {!!high && <div className={`service-board-stats-high`}>h:{high}</div>}
      {!!medium && <div className={`service-board-stats-med`}>m:{medium}</div>}
      {!!low && <div className={`service-board-stats-low`}>l:{low}</div>}
    </div>
  );
}

