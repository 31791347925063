import {AbstractStorage} from "../storage/abstract-storage";
import {localStorageService} from "../storage/local-storage";
import {ScreenUuidType} from "../firebase/database-types";
import {v4 as uuidV4} from "uuid";
import {isUndefined} from "is-type-util";

const uuidStorageKey = `identify-screen-uuid`;

const uuidWhenStorageNotAvailable: ScreenUuidType = `00000000-0000-0000-0000-000000000000`;
class ScreenIdentifyService {
  constructor(
    private readonly storage: AbstractStorage = localStorageService
  ) {
  }

  private createUuid(): ScreenUuidType {
    if (!this.storage.isAvailable) {
      // storage not available
      // can't save uuid, so need to use default uuid
      return uuidWhenStorageNotAvailable;
    }

    const screenUuid: ScreenUuidType = uuidV4();
    this.writeToStorage(screenUuid);
    return screenUuid;
  }

  private getFromStorage(): ScreenUuidType | undefined
  {
    if (!this.storage.isAvailable) {
      // storage not available
      // can't save uuid, so need to use default uuid
      return uuidWhenStorageNotAvailable;
    }

    return this.storage
      .getItem<ScreenUuidType>(uuidStorageKey);
  }
  private writeToStorage(value?: ScreenUuidType)
  {
    if (isUndefined(value)) {
      return this.storage
        .removeItem(uuidStorageKey);
    }

    return this.storage
      .setItem<ScreenUuidType>(uuidStorageKey, value);
  }

  public get uuid(): ScreenUuidType {
    return this.getFromStorage()
      ?? this.createUuid();
  }
}


export const screenIdentifyService = new ScreenIdentifyService();
