import {useEffect} from "react";
import {screenIdentifyService} from "../../../services/identify/screen-identify-service";
import {screenService} from "../../../services/screen/screen-service";
import {useAppSelector} from "../../../redux/hooks";
import {
  getScreenHasServiceBoards,
  getScreenHasTeamMembers,
  shouldScreenReload
} from "../../../redux/screen/screen.selectors";
import {displayConfigService} from "../../../services/display-config/display-config-service";
import {serviceBoardService} from "../../../services/serice-boards/service-boards-service";
import {teamMembersService} from "../../../services/team-members/team-members-service";


export const useScreenConnectionProviderLogic = () => {

  const hasTeamMembers = useAppSelector(getScreenHasTeamMembers);
  const hasServiceBoards = useAppSelector(getScreenHasServiceBoards);
  const shouldReload = useAppSelector(shouldScreenReload);

  useEffect(() => {
    if (!shouldReload) {
      // nothing to do
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set(`reloadTime`, `${Date.now()}`);
    window.location.search = queryParams.toString();
  }, [shouldReload]);

  useEffect(() => {
    // set screen uuid
    screenService.subScribeToScreenId(screenIdentifyService.uuid);
    displayConfigService.subScribe();

    return () => {
      screenService.unsubscribe();
      displayConfigService.unsubscribe();
    }
  }, [])

  useEffect(() => {
    if (!hasTeamMembers) {
      teamMembersService.unsubscribe();
      return;
    }
    teamMembersService.subScribe();

    return () => {
      teamMembersService.unsubscribe();
    }
  }, [hasTeamMembers])

  useEffect(() => {
    if (!hasServiceBoards) {
      serviceBoardService.unsubscribe();
      return;
    }
    serviceBoardService.subScribe();

    return () => {
      screenService.unsubscribe();
    }
  }, [hasServiceBoards])
}
