import React, {useEffect} from "react";
import type {VoidFunctionComponent} from "react";
import s from "./TeamMembers.module.scss";
import {useTeamMembersListLogic, useTeamMembersLogic} from "./TeamMembers.logic";
import {TeamMember} from "../team-member/TeamMember";
import {withClassNames} from "../../factories/with-class-names";
import {displayHasTeamMembersEvent, displayTeamMembersEvent} from "../../services/log/events";

const TeamMembersList = () => {
  const {teamMemberIds, theme} = useTeamMembersListLogic();

  useEffect(() => {
    displayTeamMembersEvent(teamMemberIds);
  }, [teamMemberIds])

  return (
    <div className={withClassNames(s.container, s[`theme_${theme}`])}>
      {teamMemberIds.map((teamMemberId) => <TeamMember teamMemberId={teamMemberId} key={`team-member-${teamMemberId}`} />)}
    </div>
  );
}
export interface ITeamMembersProps {
}

export const TeamMembers: VoidFunctionComponent<ITeamMembersProps> = ({}) => {
  const {hasTeamMembers} = useTeamMembersLogic();
  useEffect(() => {
    displayHasTeamMembersEvent(hasTeamMembers);
  }, [hasTeamMembers]);

  if (!hasTeamMembers) {
    return <div>No Team Members Configured</div>;
  }

  return (
    <TeamMembersList />
  );
}

