import {
  IDisplayConfigChargeCodesData,
  IDisplayConfigChargeCodesShouldShowPreviousData,
  IDisplayConfigData,
  IDisplayConfigServiceTicketWorkTypeData,
} from "../../services/firebase/database-types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export type DisplayConfigStoreChargeCodeType = IDisplayConfigChargeCodesData | IDisplayConfigChargeCodesShouldShowPreviousData;
export type DisplayConfigStoreChargeCodesType = Record<number, DisplayConfigStoreChargeCodeType>;
export type DisplayConfigStoreServiceTicketWorkTypesType = Record<number, IDisplayConfigServiceTicketWorkTypeData>;
export interface IDisplayConfigStore {
  chargeCodes?: DisplayConfigStoreChargeCodesType;
  serviceTicketWorkTypes?: DisplayConfigStoreServiceTicketWorkTypesType;
}

const initialState: IDisplayConfigStore = {

};

const displayConfigSlice = createSlice({
  name: `displayConfig`,
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<IDisplayConfigData>>) => {
      const displayConfig = action.payload;
      return {
        ...state,
        ...displayConfig,
      };
    },
    clearData:(state) => {
      return {
        ...initialState,
      };
    }
  }
});

export const {setData: setDisplayConfigData, clearData: clearDisplayConfigData} = displayConfigSlice.actions;

export const displayConfigReducer = displayConfigSlice.reducer;
