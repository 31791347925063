import {useAppSelector} from "../../redux/hooks";
import {getScreenHasTeamMembers, getScreenTeamMemberIds, getScreenTheme} from "../../redux/screen/screen.selectors";

export interface ITeamMembersListLogic {
  teamMemberIds: number[];
  theme?: string;
}

export const useTeamMembersListLogic = (): ITeamMembersListLogic => {
  const teamMemberIds = useAppSelector(getScreenTeamMemberIds);

  const theme = useAppSelector(getScreenTheme) ?? 'default';
  return {
    theme,
    teamMemberIds,
  };
}


export interface ITeamMembersLogic {
  hasTeamMembers: boolean;
}
export const useTeamMembersLogic = (): ITeamMembersLogic => {
  const hasTeamMembers = useAppSelector(getScreenHasTeamMembers);
  return {
    hasTeamMembers,
  };
}
