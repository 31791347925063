import queryString from 'query-string';

export type ParsedUrlQueryType = Record<string, string | string[]>;
export type ParsedUrlQueryInputType = Record<string, string | number | boolean | ReadonlyArray<string> | ReadonlyArray<number> | ReadonlyArray<boolean> | undefined | null>;

export const splitQueryDataFromUrl = (inputUrl: string): {url: string, query?: string} => {
  let query: string | undefined;
  let url: string;
  // split url and query string
  [url, query] = inputUrl.split(`?`, 2);

  // cleanup
  url = url.trim();
  query = query?.trim();

  // check if we have empty query string
  if (query?.length === 0){
    //query is empty, set to undefined
    query = undefined;
  }

  return {url, query};
}

export const extractQueryDataFromUrl = <ExpectedData extends ParsedUrlQueryType = any>(inputUrl: string): {url: string, data?: ExpectedData} => {

  const {url, query} = splitQueryDataFromUrl(inputUrl);

  let data;
  if (query !== undefined) {
    data = queryString.parse(query) as ExpectedData;
  }

  return {url, data};
}


export const injectQueryDataInUrl = <RequestData extends ParsedUrlQueryInputType | undefined = any>(inputUrl: string, queryData?: RequestData): string => {

  if (queryData === undefined){
    // nothing we need to do, return passed url
    return inputUrl;
  }

  const {url, data} = extractQueryDataFromUrl(inputUrl);

  const newQueryData = {
    ...(data ?? {}),
    ...queryData,
  }

  // generated query string containing passed queryData
  const queryDataString = queryString.stringify(newQueryData);

  return `${url}?${queryDataString}`;
}
