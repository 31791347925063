import type {AppState} from "../store";

import type {ITeamMembersStore} from "./team-members.slice";
import {
  ITeamMemberActivityData,
  ITeamMemberActivityWorkTypeData,
  ITeamMemberData, ITeamMemberStatsData, TeamMemberActivityType
} from "../../services/firebase/database-types";
import {isDefined} from "is-type-util";


const getTeamMembersStore = (store: AppState): ITeamMembersStore =>
  store.teamMembers;



export const getTeamMemberStoreById = (store: AppState, id: number): ITeamMemberData | undefined =>
  getTeamMembersStore(store).byId?.[id] ?? undefined;
export const getTeamMemberFirstNameById = (store: AppState, id: number): string =>
  `${getTeamMemberStoreById(store, id)?.firstName ?? `unknown`}`;
export const getTeamMemberLastNameById = (store: AppState, id: number): string =>
  `${getTeamMemberStoreById(store, id)?.lastName ?? `unknown`}`;
export const getTeamMemberNameById = (store: AppState, id: number): string =>
  `${getTeamMemberFirstNameById(store, id)} ${getTeamMemberLastNameById(store, id)}`;

const getStatsForTeamMemberId = (store: AppState, teamMemberId: number): ITeamMemberStatsData | undefined =>
  getTeamMemberStoreById(store, teamMemberId)?.stats;

export const getAssignedTicketStatForTeamMemberId = (store: AppState, teamMemberId: number): number =>
  getStatsForTeamMemberId(store, teamMemberId)?.assigned ?? 0;

export const doesTeamMemberHaveAnyActivityToday = (store: AppState, id: number): boolean => {
  const {active, lastActive} = getTeamMemberStoreById(store, id) ?? {};

  return (
    isDefined(active)
    || isDefined(lastActive)
    // TODO: also include if they have logged into connectwise
  );
}
export const getCurrentActivityByTeamMemberId = (store: AppState, id: number): Partial<ITeamMemberActivityData> =>
  getTeamMemberStoreById(store, id)?.active ?? {};

export const hasTeamMemberCurrentActivityById = (store: AppState, id: number): boolean =>
  isDefined(getCurrentActivityByTeamMemberId(store, id).timeStart);

export const getTeamMemberCurrentActivityIdById = (store: AppState, id: number): number | undefined =>
  getCurrentActivityByTeamMemberId(store, id)?.id;
export const getTeamMemberCurrentActivityTypeById = (store: AppState, id: number): TeamMemberActivityType | undefined =>
  getCurrentActivityByTeamMemberId(store, id)?.type;
export const getTeamMemberCurrentActivityCompanyById = (store: AppState, id: number): string | undefined =>
  getCurrentActivityByTeamMemberId(store, id)?.company;
export const getTeamMemberCurrentActivityStartTimeById = (store: AppState, id: number): string | undefined =>
  getCurrentActivityByTeamMemberId(store, id)?.timeStart;
export const getTeamMemberCurrentActivityEndTimeById = (store: AppState, id: number): string | undefined =>
  getCurrentActivityByTeamMemberId(store, id)?.timeEnd;

export const getTeamMemberCurrentActivityWorkTypeById = (store: AppState, id: number): ITeamMemberActivityWorkTypeData | undefined =>
  getCurrentActivityByTeamMemberId(store, id)?.workType;

export const getTeamMemberCurrentActivityWorkTypeIdById = (store: AppState, id: number): number | undefined =>
  getTeamMemberCurrentActivityWorkTypeById(store, id)?.id;
export const getTeamMemberCurrentActivityWorkTypeNameById = (store: AppState, id: number): string | undefined =>
  getTeamMemberCurrentActivityWorkTypeById(store, id)?.name;
export const getTeamMemberLastActivityById = (store: AppState, id: number): Partial<ITeamMemberActivityData> =>
  getTeamMemberStoreById(store, id)?.lastActive ?? {};

export const hasTeamMemberLastActivityById = (store: AppState, id: number): boolean =>
  isDefined(getTeamMemberLastActivityById(store, id));
export const getTeamMemberLastActivityIdById = (store: AppState, id: number): number | undefined =>
  getTeamMemberLastActivityById(store, id)?.id;
export const getTeamMemberLastActivityCompanyById = (store: AppState, id: number): string | undefined =>
  getTeamMemberLastActivityById(store, id)?.company;
export const getTeamMemberLastActivityStartTimeById = (store: AppState, id: number): string | undefined =>
  getTeamMemberLastActivityById(store, id)?.timeStart;
export const getTeamMemberLastActivityEndTimeById = (store: AppState, id: number): string | undefined =>
  getTeamMemberLastActivityById(store, id)?.timeEnd ?? getTeamMemberCurrentActivityEndTimeById(store, id);

export const getTeamMemberLastActivityWorkTypeById = (store: AppState, id: number): ITeamMemberActivityWorkTypeData | undefined =>
  getTeamMemberLastActivityById(store, id)?.workType;

export const getTeamMemberLastActivityWorkTypeIdById = (store: AppState, id: number): number | undefined =>
  getTeamMemberLastActivityWorkTypeById(store, id)?.id;
export const getTeamMemberLastActivityWorkTypeNameById = (store: AppState, id: number): string | undefined =>
  getTeamMemberLastActivityWorkTypeById(store, id)?.name;
