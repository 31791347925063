import {AppState} from "../store";
import {IConnectionStore} from "./connection.slice";
import {isDefined} from "is-type-util";

const getConnectionStore = (store: AppState): IConnectionStore =>
  store.connection;

export const getConnectionUuid = (store: AppState): string|undefined =>
  getConnectionStore(store).uuid;
export const getConnectionToken = (store: AppState): string|undefined =>
  getConnectionStore(store).token;

export const doesConnectionHaveToken = (store: AppState): boolean =>
  (isDefined(getConnectionUuid(store)) && isDefined(getConnectionToken(store)));


export const istConnectionAuthenticated = (store: AppState): boolean =>
  getConnectionStore(store).isAuthenticated ?? false;
export const doesConnectionHaveError = (store: AppState): boolean =>
  isDefined(getConnectionStore(store).error);

