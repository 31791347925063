import React from "react";
import type {VoidFunctionComponent} from "react";
import s from "./TimeAgo.module.scss";
import {useTimeAgoLogic} from "./TimeAgo.logic";
import {toLocalTime} from "../../../factories/time";

export interface ITimeAgoProps {
  timestamp?: string;
}

export const TimeAgo: VoidFunctionComponent<ITimeAgoProps> = ({timestamp}) => {
  const {timeAgo} = useTimeAgoLogic(timestamp);
  return (<> {toLocalTime(`${timestamp}`)} <span className={s.ago}>[ {timeAgo ?? `...`} ]</span> </>);
}

