import {useAppSelectorWithParam} from "../../redux/hooks";
import {
  getTotalTicketStatsByServiceBoardId, getTotalUnAssignedTicketsByServiceBoardId
} from "../../redux/service-boards/service-boards.selectors";

export interface IServiceBoardLogic {
  total: number;
  available: number;
}

export const useServiceBoardLogic = (serviceBoardId: number): IServiceBoardLogic => {
  const total = useAppSelectorWithParam(getTotalTicketStatsByServiceBoardId, serviceBoardId) ?? 0;
  const available = useAppSelectorWithParam(getTotalUnAssignedTicketsByServiceBoardId, serviceBoardId) ?? 0;
  return {total, available};
}
