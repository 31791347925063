import preval from 'preval.macro';
import pkg from '../../package.json';

/**
 * Due to the way webpack 5 replaces "process.env" with an object
 * it is better to use a variable for all access to env variables
 */
export const buildEnvironment = process.env;

export const getEnv = (key: string): string | undefined =>
  buildEnvironment[key] ?? undefined;

// dev when dev build
const buildVersionEnv = `${getEnv(`REACT_APP_ENV`)}`;


// build date if we have one
export const appBuildVersionDate = preval`module.exports = new Date().toLocaleDateString()`;

// build number if we have one
export const appBuildVersionNumber = pkg.version;


/**
 * App release version short from build environment
 */
export const appReleaseVersion = `v${appBuildVersionNumber} - ${appBuildVersionDate}`;

/**
 * App version full from build environment
 */
export const appEnvVersion = `Version ${appReleaseVersion} ${buildVersionEnv}`;

/**
 * App was built with as deployable build
 */
export const isAppEnvProduction = getEnv(`NODE_ENV`) === 'production';

/**
 * if app was built for dev env
 */
export const isAppEnvDev = getEnv(`REACT_APP_ENV`) === 'dev';

/**
 * if app was built for local dev env
 *
 */
export const isAppEnvLocalDev = isAppEnvDev && !isAppEnvProduction;

