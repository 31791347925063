import {screensClient} from "../../clients/911it-system/screens-client";
import {screenIdentifyService} from "../identify/screen-identify-service";
import {ReduxStateService} from "../state/redux-state";
import {setToken, setStatus, setError} from "../../redux/connection/connection.slice";
import {firebaseAuth, signInWithToken} from "../../clients/firebase/auth";
import {isDefined} from "is-type-util";
import {firebaseDatabaseClient} from "../../clients/firebase/database";
import {productMonitoring} from "../log/product-monitoring-service";
import {authenticatedEvent} from "../log/events";


class ConnectionService {

  public setStatus(isAuthenticated: boolean): void
  {
    authenticatedEvent(isAuthenticated);
    ReduxStateService.dispatch(setStatus(isAuthenticated));
  }

  public async authenticate() {
    const uuid = screenIdentifyService.uuid;
    try {
      const {data} = await screensClient.createOne<{status: boolean, uuid: string, token: string}>({uuid});
      const {token, } = data;
      ReduxStateService.dispatch(setToken({token, uuid}));
      void await signInWithToken(token)
        .catch((error) => {
          console.error(`signInWithToken`, {error});
          productMonitoring.logException(error, {action: `signInWithToken`});
          ReduxStateService.dispatch(setError(`${error}`));
        });
    } catch (e: unknown) {
      productMonitoring.logException(e as Error, {action: `getScreenToken`});
      ReduxStateService.dispatch(setError(`${e}`));
    }
  }

  public async setConnectionPresence()
  {
    const uuid = screenIdentifyService.uuid;
    void firebaseDatabaseClient.setOnlinePresence(`/screens/${uuid}/presence`)

  }
}


export const connectionService = new ConnectionService();

firebaseAuth.onAuthStateChanged((user?: unknown) => connectionService.setStatus(isDefined(user)));
