import {AbstractStorage} from "./abstract-storage";

export class LocalStorageService extends AbstractStorage {

  protected getStorage(): Storage | undefined {
    return window?.localStorage ?? undefined;
  }

}

export const localStorageService = new LocalStorageService();
