import {firebaseDatabaseService} from "../firebase/database";
import {store} from "../../redux/store";
import {clearTeamMembers, loadTeamMembers} from "../../redux/team-members/team-members.slice";
import {ITeamMemberData} from "../firebase/database-types";


class TeamMembersService {
  protected unsubscribeCallback?: () => void;

  protected handleUnsubscribe(): void {
    this.unsubscribeCallback?.();
    this.unsubscribeCallback = undefined;
  }

  public subScribe(){
    // make sure not already subscribed
    this.handleUnsubscribe();

    this.unsubscribeCallback = firebaseDatabaseService.subscribeToTeamMembers(this.handleChange.bind(this));
  }

  public unsubscribe(): void {
    this.handleUnsubscribe();
    store.dispatch(clearTeamMembers());
  }

  public handleChange(newValue?: Record<number, ITeamMemberData>){
    store.dispatch(loadTeamMembers(Object.values(newValue ?? {})));
  }
}

export const teamMembersService = new TeamMembersService();
