
import {useAppSelector} from "../../redux/hooks";
import {getScreenTheme} from "../../redux/screen/screen.selectors";

export interface IWallboardPageLogic {
  theme?: string;
}
export const useWallboardPageLogic = (): IWallboardPageLogic => {
  const theme = useAppSelector(getScreenTheme) ?? 'default';
  return {theme};
}
