import React from "react";
import type {VoidFunctionComponent} from "react";
import {useTeamMemberCurrentActivityTimeLogic} from "./TeamMemberCurrentActivityTime.logic";

export interface ITeamMemberCurrentActivityTimeProps {
  teamMemberId: number;
}

export const TeamMemberCurrentActivityTime: VoidFunctionComponent<ITeamMemberCurrentActivityTimeProps> = ({teamMemberId}) => {
  const {startTime} = useTeamMemberCurrentActivityTimeLogic(teamMemberId);
  return (<> {startTime ?? `...`} </>);
}

