import type {AppState} from "../store";
import type {ScreenStoreType} from "./screen.slice";
import {IScreenPresenceData, ScreenDisplayType, ScreenUuidType} from "../../services/firebase/database-types";
import {isDefined} from "is-type-util";


const getScreenStore = (store: AppState): ScreenStoreType =>
  store.screen;

export const getScreenUuid = (store: AppState): ScreenUuidType | undefined =>
  getScreenStore(store).uuid;

export const isScreenSetup = (store: AppState): boolean => {
  const {uuid, doesNeedSetup, ...otherData} = getScreenStore(store);
  return (
    !doesNeedSetup
    && !!Object.keys(otherData).length
  );
}


export const hasScreenUuid = (store: AppState): boolean =>
  isDefined(getScreenUuid(store));
export const getScreenType = (store: AppState): ScreenDisplayType | undefined =>
  getScreenStore(store).type;
export const getScreenTheme = (store: AppState): string | undefined =>
  getScreenStore(store).theme;
export const getScreenTitle = (store: AppState): string =>
  `${getScreenStore(store).title ?? `unknown`}`;

export const getScreenTeamMemberIds = (store: AppState): number[] =>
  getScreenStore(store).teamMembers ?? [];
export const getScreenHasTeamMembers = (store: AppState): boolean =>
  getScreenTeamMemberIds(store).length > 0;

export const getScreenServiceBoardIds = (store: AppState): number[] =>
  getScreenStore(store).serviceBoards ?? [];
export const getScreenHasServiceBoards = (store: AppState): boolean =>
  getScreenServiceBoardIds(store).length > 0;

const getScreenPresenceStore = (store: AppState): IScreenPresenceData =>
  getScreenStore(store).presence ?? {};

export const shouldScreenReload = (store: AppState): boolean =>
  getScreenPresenceStore(store).shouldReload ?? false;
