import React from "react";
import type {VoidFunctionComponent} from "react";
import s from "./ServiceBoard.module.scss";
import {useServiceBoardLogic} from "./ServiceBoard.logic";
import {ServiceBoardTitle} from "./title/ServiceBoardTitle";
import {ServiceBoardAvailableTickets} from "./available-tickets/ServiceBoardAvailableTickets";

export interface IServiceBoardProps {
  serviceBoardId: number;
}

export const ServiceBoard: VoidFunctionComponent<IServiceBoardProps> = ({serviceBoardId}) => {
  const {available, total} = useServiceBoardLogic(serviceBoardId);
  // return <div className={s.container}> ServiceBoard </div>;
  return (
    <div className={s.container}>
      <ServiceBoardTitle serviceBoardId={serviceBoardId} />
      <div className={s.availableOverTotal}>{available}/{total}</div>
      <ServiceBoardAvailableTickets serviceBoardId={serviceBoardId} />
    </div>
  );
}

