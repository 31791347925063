import {useAppSelector} from "../../redux/hooks";
import {getScreenServiceBoardIds} from "../../redux/screen/screen.selectors";

export interface IServiceBoardsListLogic {
  serviceBoardIds: number[];
}

export const useServiceBoardsListLogic = (): IServiceBoardsListLogic => {
  const serviceBoardIds =  useAppSelector(getScreenServiceBoardIds);
  return {
    serviceBoardIds,
  };
}
