import {useAppSelectorWithParam} from "../../../../redux/hooks";
import {getTeamMemberLastActivityEndTimeById} from "../../../../redux/team-members/team-members.selectors";

export interface ITeamMemberPreviousActivityTimeLogic {
  timestamp?: string;
}

export const useTeamMemberPreviousActivityTimeLogic = (teamMemberId: number): ITeamMemberPreviousActivityTimeLogic => {
  const timestamp = useAppSelectorWithParam(getTeamMemberLastActivityEndTimeById, teamMemberId);
  return {timestamp};
}
