import {useAppSelector} from "../../../redux/hooks";
import {getServiceBoardAvailableTicketsById} from "../../../redux/service-boards/service-boards.selectors";

export interface IServiceBoardAvailableTicketsLogic {
  low?: number;
  medium?: number;
  high?: number;
  critical?: number;
}

export const useServiceBoardAvailableTicketsLogic = (serviceBoardId: number): IServiceBoardAvailableTicketsLogic => {

  const {low, medium, high, critical} = useAppSelector((state) => getServiceBoardAvailableTicketsById(state, serviceBoardId))
  return {
    low,
    medium,
    high,
    critical,
  };
}
