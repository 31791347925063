import React, {useEffect} from "react";
import type {VoidFunctionComponent} from "react";
import s from "./WallboardTop.module.scss";
import {useWallboardTopLogic} from "./WallboardTop.logic";
import {ServiceBoardsList} from "../../../../modules/service-boards/ServiceBoardsList";
import {displayHasServiceBoardsEvent} from "../../../../services/log/events";

export interface IWallboardTopProps {
}

export const WallboardTop: VoidFunctionComponent<IWallboardTopProps> = ({}) => {
  const {hasServiceBoards} = useWallboardTopLogic();
  useEffect(() => {
    displayHasServiceBoardsEvent(hasServiceBoards);
  }, [hasServiceBoards]);
  if (!hasServiceBoards) {
    return null;
  }
  return (
    <div className={s.container}>
      <div className={s.serviceBoards}>
        <ServiceBoardsList />
      </div>
      <div className={s.companyStats + ` service-board-stats`}>
        <div className={`logo-container`}>
          <img className={`logo`} src={``} />
        </div>
        <div className={`stats-container`}>
          <div className={`ticket-total-open-stat`}>{10} Open Total</div>
          <div className={`ticket-opened-stat`}>{10} Open Today</div>
          <div className={`ticket-closed-stat`}>{10} Closed Today</div>
        </div>
      </div>
    </div>
  );
}

