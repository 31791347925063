// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBamECDHB5AY2rL2BQYNkzx6-o9nWln2TE",
  authDomain: "it-wallboard.firebaseapp.com",
  databaseURL: "https://it-wallboard-default-rtdb.firebaseio.com",
  projectId: "it-wallboard",
  storageBucket: "it-wallboard.appspot.com",
  messagingSenderId: "473046209721",
  appId: "1:473046209721:web:e14822709dbdaf152cf768",
  measurementId: "G-D4LY7Z4R44"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
