import {isDefined} from "is-type-util";

export abstract class AbstractStorage {
  /**
   * if storage is available
   */
  public get isAvailable(): boolean {
    return isDefined(this.getStorage());
  }

  /**
   * this needs to return Storage object if available
   * @protected
   */
  protected abstract getStorage(): Storage | undefined;

  /**
   * get item from storage if available
   * @param key
   */
  public getItem<ItemType extends string = string>(key: string): ItemType | undefined {
    try {
      return this.getStorage()?.getItem(key) as ItemType | undefined;
    } catch (_e) {
      // we don't have access, return undefined
      return undefined;
    }
  }

  /**
   * get item from storage and parse item as JSON if available
   * @param key
   */
  getJsonItem<ItemType = unknown>(key: string): ItemType | undefined {
    let itemAsString = this.getItem(key);
    let itemFromJson;
    if (itemAsString !== undefined && itemAsString !== null) {
      itemFromJson = JSON.parse(itemAsString) as ItemType;
    }
    return itemFromJson;
  }

  /**
   * set item to storage if storage is available
   * @param key
   * @param item
   */
  public setItem<ItemType extends string>(key: string, item: ItemType): void {
    try {
      this.getStorage()?.setItem(key, item);
    } catch (_e) {
      // we have no access to storage, nothing we can do
    }
  }

  /**
   * convert item to JSON string and store item to storage if storage is available
   * @param key
   * @param item
   */
  public setJsonItem<ItemType>(key: string, item: ItemType): void {
    this.setItem(key, JSON.stringify(item));
  }


  /**
   * remove item from storage if storage is available
   * @param key
   */
  public removeItem(key: string): void {
    try {
      this.getStorage()?.removeItem(key);
    } catch (_e) {
      // we have no access to storage, nothing we can do
    }
  }


  /**
   * clear all items in storage if storage is available
   */
  public clear(): void {
    try {
      this.getStorage()?.clear();
    } catch (_e) {
      // we have no access to storage, nothing we can do
    }
  }
}
