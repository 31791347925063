import React from "react";
import type {FunctionComponent} from "react";
import {useServiceBoardTitleLogic} from "./ServiceBoardTitle.logic";
// @ts-ignore
import s from "./ServiceBoardTitle.module.scss";

export interface IServiceBoardTitleProps {
    serviceBoardId: number;
}

export const ServiceBoardTitle: FunctionComponent<IServiceBoardTitleProps> = ({serviceBoardId}) => {
    const title = useServiceBoardTitleLogic(serviceBoardId);
    return (
        <div className={s.title}>{title}</div>
    );
};

