import {firebaseDatabaseService} from "../firebase/database";
import {clearScreen, setScreenData, setScreenUuid} from "../../redux/screen/screen.slice";

import {IScreenData, ScreenUuidType} from "../firebase/database-types";
import {ReduxStateService} from "../state/redux-state";
import {FirebaseDatabaseUpdateClient} from "../../clients/firebase/database-update";
import {ScreensClient, screensClient} from "../../clients/911it-system/screens-client";
import {productMonitoring} from "../log/product-monitoring-service";
import {screenIdentifyEvent} from "../log/events";


class ScreenService {
  protected uuid?: ScreenUuidType;
  protected firebaseUpdateClient?: FirebaseDatabaseUpdateClient;
  protected unsubscribeCallback?: () => void;

  protected handleUnsubscribe(): void {
    this.unsubscribeCallback?.();
    this.unsubscribeCallback = undefined;
    this.uuid = undefined;
    this.firebaseUpdateClient = undefined;
  }

  protected get firebaseClient(): FirebaseDatabaseUpdateClient
  {
    this.firebaseUpdateClient ??= new FirebaseDatabaseUpdateClient(`/screens/${this.uuid}`);
    return this.firebaseUpdateClient;
  }
  protected get systemClient(): ScreensClient
  {
    return screensClient;
  }

  public subScribeToScreenId(screenUuid: ScreenUuidType){
    if (this.uuid === screenUuid) {
      // nothing to do
      // return to exit
      return;
    }
    // make sure not already subscribed
    this.handleUnsubscribe();
    this.uuid = screenUuid;
    screenIdentifyEvent(screenUuid);
    ReduxStateService.dispatch(setScreenUuid(screenUuid));

    this.unsubscribeCallback = firebaseDatabaseService.subscribeToScreen(screenUuid, this.handleScreenChange.bind(this));
  }

  public unsubscribe(): void {
    this.handleUnsubscribe();
    ReduxStateService.dispatch(clearScreen());
  }

  public handleScreenChange(newValue?: IScreenData | null){
    if (newValue === null) {
      // screen not configured
      ReduxStateService.dispatch(setScreenData(undefined));
      // return to exit
      return;
    }
    ReduxStateService.dispatch(setScreenData(newValue));
  }
}

export const screenService = new ScreenService();
