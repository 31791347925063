import type {AppState} from "../store";
import type {IServiceBoardsStore} from "./service-boards.slice";
import {
  IServiceBoardAvailableTicketsData,
  IServiceBoardData,
  IServiceBoardStatsData
} from "../../services/firebase/database-types";
import exp from "constants";

const getServiceBoardsStore = (store: AppState): IServiceBoardsStore =>
  store.serviceBoards;

export const getServiceBoardStoreById = (store: AppState, id: number): IServiceBoardData | undefined =>
  getServiceBoardsStore(store).byId?.[id] ?? undefined;
export const getServiceBoardNameById = (store: AppState, id: number): string =>
  `${getServiceBoardStoreById(store, id)?.name ?? `unknown`}`;

export const getStatsByServiceBoardId = (store: AppState, id: number): IServiceBoardStatsData | undefined =>
  getServiceBoardStoreById(store, id)?.stats;

export const getTotalTicketStatsByServiceBoardId = (store: AppState, id: number): number | undefined =>
  getStatsByServiceBoardId(store, id)?.total;

export const getServiceBoardAvailableTicketsById = (store: AppState, id: number): Partial<IServiceBoardAvailableTicketsData> =>
  getServiceBoardStoreById(store, id)?.availableTickets ?? {};

export const getServiceBoardCriticalTicketsById = (store: AppState, id: number): number =>
  getServiceBoardAvailableTicketsById(store, id).critical ?? 0;
export const getServiceBoardHighTicketsById = (store: AppState, id: number): number =>
  getServiceBoardAvailableTicketsById(store, id).high ?? 0;
export const getServiceBoardMediumTicketsById = (store: AppState, id: number): number =>
  getServiceBoardAvailableTicketsById(store, id).medium ?? 0;
export const getServiceBoardLowTicketsById = (store: AppState, id: number): number =>
  getServiceBoardAvailableTicketsById(store, id).low ?? 0;
export const getTotalUnAssignedTicketsByServiceBoardId = (store: AppState, id: number): number => {
  const critical = getServiceBoardCriticalTicketsById(store, id);
  const high = getServiceBoardHighTicketsById(store, id);
  const medium = getServiceBoardMediumTicketsById(store, id);
  const low = getServiceBoardLowTicketsById(store, id);
  return critical + high + medium + low;
}



