import {
  getDatabase,
  ref as firebaseRef,
  onValue as firebaseOnValue,
  onDisconnect as firebaseOnDisconnect,
  serverTimestamp as firebaseServerTimestamp,
  off as firebaseOff,
  set as firebaseSet,
  get as firebaseGet,
  update as firebaseUpdate
} from "firebase/database";
import type {Database as FirebaseDatabaseType, DatabaseReference as FirebaseDatabaseReferenceType} from "firebase/database";
import {firebaseApp} from "./app";
import {IScreenConfigData, IScreenPresenceData} from "../../services/firebase/database-types";
import {appBuildVersionDate, appBuildVersionNumber} from "../../config/environment";


export const firebaseDatabase = getDatabase(firebaseApp) as FirebaseDatabaseType;

export class FirebaseDatabaseClient {

  private refs: Record<string, FirebaseDatabaseReferenceType> = {};

  private createRef(basePath: string): FirebaseDatabaseReferenceType {
    this.refs[basePath] ??= firebaseRef(firebaseDatabase, `${basePath}`);
    return this.refs[basePath];
  }

  public async readOnce<ResponseType = unknown>(path: string): Promise<ResponseType | undefined> {
    return new Promise((resolve, reject) => {
      firebaseGet(
        this.createRef(path)
      )
        .then((snapshot) => {
          if (!snapshot.exists()){
            resolve(undefined);
          }
          resolve(snapshot.val() as ResponseType)
        });
    });
  }

  public subscribe<ResponseType = unknown>(path: string, callback: (response: ResponseType) => void): () => void{
    return firebaseOnValue(
      this.createRef(path),
      (snapshot) => {
        callback(snapshot.val());
      }
    );
  }

  public async set<ValueType = unknown>(path: string, value: ValueType): Promise<void> {
    void firebaseSet(
      this.createRef(path),
      value,
    );
  }

  public async update(basePath: string, changes: Record<string, unknown>): Promise<void> {
    void firebaseUpdate(
      this.createRef(basePath),
      changes,
    );
  }

  public async setOnlinePresence(path: string): Promise<() => void>
  {
    const presenceRef = this.createRef(path);

    const presenceBase: IScreenPresenceData = {
      buildVersion: appBuildVersionNumber,
      buildDate: appBuildVersionDate,
    };

    void await firebaseSet(
      presenceRef,
      <IScreenPresenceData>{
        ...presenceBase,
        connectedAt: firebaseServerTimestamp() as unknown as number,
        lastOnline: `connected`,
      }
    );
    const onDisconnectRef = firebaseOnDisconnect(presenceRef);
    void onDisconnectRef.set(<IScreenPresenceData>{
      ...presenceBase,
      lastOnline: firebaseServerTimestamp() as unknown as number,
    });

    return () => onDisconnectRef.cancel();
  }


}



export const firebaseDatabaseClient = new FirebaseDatabaseClient();


