import {logEvent, setUserProperties} from "../../clients/firebase/analytics";

class ProductMonitoringService {

  public identify(key: string, value: unknown): void
  {
    setUserProperties(key, value);
  }

  public logEvent(eventName: string, eventProperties?: Record<string, unknown>): void
  {
    logEvent(eventName, eventProperties);

  }
  public logException(exception: Error, extraData?: {[key: string]: string | number | boolean}): void
  {

    this.logEvent(`exception`, {
      exception,
      details: extraData,
    });

  }
}

export const productMonitoring = new ProductMonitoringService();
