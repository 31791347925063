import type { Store, AnyAction, ThunkAction } from "@reduxjs/toolkit";

import {isUndefined} from "is-type-util";
import {AppState} from "../../redux/store";

/**
 * provide clean access to redux state and dispatch without risk of import loop
 */
export class ReduxStateService {
  protected static store: Store;

  public static setStore(store: Store): void {
    this.store = store;
  }

  protected static getStore(): Store {
    if (isUndefined(this.store)) {
      throw new Error('store not set, unable to access');
    }
    return this.store;
  }

  public static getState(): AppState {
    return this.getStore().getState();
  }

  public static getSelector<SelectorReturnType = unknown>(selector: (state: AppState) => SelectorReturnType): SelectorReturnType {
    return selector(this.getState());
  }

  public static dispatch(action: AnyAction | ThunkAction<void, AppState, unknown, AnyAction>): void {
    this.getStore().dispatch(action as AnyAction);
  }
}
