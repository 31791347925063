import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {screenReducer} from "./screen/screen.slice";
import {serviceBoardsReducer} from "./service-boards/service-boards.slice";
import {teamMembersReducer} from "./team-members/team-members.slice";
import {connectionReducer} from "./connection/connection.slice";
import {displayConfigReducer} from "./display-config/display-config.slice";

export const store = configureStore({
  reducer: {
    connection: connectionReducer,
    displayConfig: displayConfigReducer,
    screen: screenReducer,
    serviceBoards: serviceBoardsReducer,
    teamMembers: teamMembersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
